import React from 'react';
import Profile from '../assets/images/no_profile2.png'
export default function Avatars(props){

var { item } = props;
return(
        <div>
                <img src={Profile} className={item} alt="test" />        
        </div>

)}