import Indexs from "App";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import {Provider} from 'react-redux'
import {store} from './actions/redux/store'
ReactDOM.render(
  <React.StrictMode>
  <Router>
  <Provider  store={store}>
    <Indexs />
   </Provider>
  </Router> 
  </React.StrictMode>
  ,
  document.getElementById('root')
);