// import React, {
//     forwardRef,
// } from 'react'
// import { Button } from '@material-ui/core';
// import config from '../../lib/config';
// import { toast } from 'react-toastify';
// import { useSelector } from "react-redux";


import React, {
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle
} from 'react';
import {
    useHistory,
    useLocation
} from "react-router-dom";
import { Button } from '@material-ui/core';


import Web3 from 'web3';
import '@metamask/legacy-web3'

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/landingPage.js";

// import EXCHANGE from '../../ABI/EXCHANGE.json'

import config from '../../lib/config';
import { toast } from 'react-toastify';
// import { GetItemList } from 'actions/items';

import { useSelector, useDispatch } from "react-redux";
import isEmpty from 'lib/isEmpty';
import PreviewCard from './PreviewCard';


toast.configure();
let toasterOption = config.toasterOption;



export const ViewBulkFiles = forwardRef((props) => {
    console.log('lsjfjjjjjjj',props)
    const dispatch = useDispatch();

    const useStyles = makeStyles(styles);

    const classes = useStyles()

    const history = useHistory()
    const Wallet_Details = useSelector(state => state.wallet_connect_context);

    const { Files, ThumbFiles, ChangeView, TokenQuantity, Pathname, Layertype, Popup } = props;
    const [imagearray,SetImageArray] = useState([])

    useEffect(()=>{
        if(typeof(ThumbFiles) == 'string'){
            var arr = [];
            for( var i= 0 ; i<TokenQuantity; i++){
                arr.push(config.Back_URL+'/'+ThumbFiles+'/'+`Thumb${i}.png`)
            }
            SetImageArray(arr)
        }
        else if(typeof(Files) == 'string'){
            var arr = [];
            for( var i= 0 ; i<TokenQuantity; i++){
                arr.push(Files+'/'+`Thumb${i}.png`)
            }
            SetImageArray(arr)
        }
    },[Files,ThumbFiles])


    const GoBack = () => {
        ChangeView(false)
        if(Popup){
            window.$('#create_item_modal2').modal('show');
        }
    }

    const FirstCaps = ( data ) => {
        return data[0].toLocaleUpperCase()+data.slice(1);
    }

    return (
        <div className={classes.pageHeader + " inner_pageheader"}>
        <div className={classes.container}>
        <div className="d-flex align-items-center">
            <i class="fas fa-arrow-left arrow_back cursor" onClick={()=>GoBack()}/>
        </div>
        <div className="container mt-5">
                
                {/* <div className='row'>
                    <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12'>
                    <PreviewCard/>
                    </div>
                    <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12'>
                    <PreviewCard/>
                    </div>
                    <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12'>
                    <PreviewCard/>
                    </div>
                    <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12'>
                    <PreviewCard/>
                    </div>
                </div> */}
                <h1 className="primary_label uploaded_file">Uploaded Files:</h1>
            {
            (Files?.length > 0 && (Pathname != 'layer' || (Layertype != 'Image' && Pathname == 'layer' && imagearray?.length > 0)) ) ?
                <>
                    <div className='row'>
                    {(Object.keys(Files)).map((val,ind)=>
                        <>
                            {/* {
                            Files[val]?.type?.includes('audio') ?
                                <audio 
                                    src={URL.createObjectURL(Files[val])} 
                                    controls
                                    controlsList="nodownload"
                                />
                                : (Files[val]?.type?.includes('video') ?
                                <video 
                                    src={URL.createObjectURL(Files[val])}
                                    loop={true}
                                    controlsList="nodownload"
                                    muted
                                    autoPlay={true}
                                    controls
                                    // poster={thumb}
                                    onContextMenu="return false;"
                                    type="video/*"
                                    // onError={event => {
                                    //   event.target.src = origFile
                                    // }}
                                />
                                : <img src={URL.createObjectURL(Files[val])}/>)
                            } */}
                            {/* { ( (ind + 1) % 4 == 0) && <br/> } */}
                            <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12'>
                            {(Pathname != 'image') ?
                            <PreviewCard 
                                previewFile={Files[val]}
                                previewThumb={typeof(ThumbFiles) == 'string'? imagearray[ind] :ThumbFiles[val]}
                            />
                            :
                            <PreviewCard 
                                previewFile={Files[val]}
                                // previewThumb={ThumbFiles[val]}
                            />}
                            </div>
                        </>
                    )}
                    </div>
                </>
                :
                (typeof(ThumbFiles) == 'string' && imagearray?.length > 0) ?
                <>
                    <div className='row'>
                    {imagearray.map((val,ind)=>
                        <>
                        <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12'>
                            <PreviewCard 
                                previewFile={val}
                                // previewThumb={ThumbFiles[val]}
                            />
                        </div>
                        </>
                    )}
                    </div>
                </>
                :
                <>
                    <p className="form_note">No Files Uploaded  For Preview</p>
                </>
            }
            
            {/* { Pathname !== 'image' && 
            <>{(ThumbFiles?.length > 0 && typeof(ThumbFiles) !== 'string' ) ?
                <>
                    <h1 className="primary_label">Uploaded Thump Images:</h1>
                    <div className='row'>
                    {Object.keys(ThumbFiles).map((val,ind)=>
                        <>
                            {
                            ThumbFiles[val]?.type?.includes('image') && <img src={URL.createObjectURL(ThumbFiles[val])} height={50} width={50}/>
                            }
                            
                            <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12'>
                            <PreviewCard 
                                preview={ThumbFiles[val]}
                            />
                            </div>
                            { ((ind + 1) % 4 == 0) && <br/> }
                        </>
                    )}
                    </div>
                </>
                :
                typeof(ThumbFiles) == 'string' ?
                <>
                    <h1 className="primary_label">Uploaded Thump Images:</h1>
                    {imagearray.map((val,ind)=>
                        <>
                        {console.log('Thumbvalvalval',val)}
                            <img src={val} height={50} width={50}/>
                            <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12'>
                            <PreviewCard 
                                preview={val}
                            />
                            </div>
                            { ((ind + 1) % 4 == 0) && <br/> }
                        </>
                    )}
                </>
                :
                <>
                    <h1 className="primary_label">Uploaded Thump Images:</h1>
                    <p className="form_note">No Files Uploaded  For Preview</p>
                </>
            }</>
            }  */}

            </div>
        </div>
        </div>
       
    )
})

